.caseStudies_div {
  text-align: center;
  padding-top: 45px;
  background-color: #c1c1c1;
  background: linear-gradient(45deg, #fe602f, #e6c7eb, #f5c645, #64cebb);
  background: linear-gradient(35deg, #fe602f, #e6c7eb, #f5c645, #4660be);
}

.caseStudies_div h1 {
  font-size: 40px;
  padding: 12px;
}

.caseStudies_inner_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.study {
  max-width: 650px;
  background-color: black;
  padding: 20px;
  margin: 20px;
}

.study:first-child {
  border-radius: 25px 25px 0 0;
}

.study:last-child {
  border-radius: 0 0 25px 25px;
}

.study h2 {
  color: #fff;
  font-size: 25px;
}

.study img {
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5); /* White shadow */
  margin: 15px 0;
}

.study p {
  text-align: start;
  background-color: #fff;
  padding: 20px;
  font-size: 18px;
  border-radius: 8px;
}
