.services_div {
  background-color: #c1c1c1;
  text-align: center;
  padding-top: 45px;
  background: linear-gradient(35deg, #fe602f, #e6c7eb, #f5c645, #4660be);
}

.services_div h1 {
  font-size: 40px;
  padding: 12px;
}

.services_inner_div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 60px;
  text-align: center;
  align-items: start;
}

.services_service {
  width: 350px;
  background-color: black;
  padding: 25px;
  color: #ffffff;
  border-radius: 8px;
}

.services_service h2 {
  height: 70px;
}

.services_service img {
  height: 150px;
  margin: 15px 0;
}

.services_service p {
  height: 300px;
  font-size: 20px;
}

/* Fade */
.service-fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.service-fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media screen and (max-width: 500px) {
  .services_service p,
  .services_service h2 {
    height: auto;
  }

  .services_service img {
    margin: 20px 0;
  }
}

.white-background {
  background-color: #ffffff;
  padding: 10px;
}
