.home {
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #f6f7f9 26px
    ),
    repeating-linear-gradient(#c1c1c155, #c1c1c1);
}

.sss {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 100px 0;
  height: 87vh;
}

.sss img {
  width: 500px;
  border-radius: 30px 0 0 30px;
}

.sss div {
  width: 500px;
  height: 500px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 20px;
  border-radius: 0 30px 30px 0;
}

.home_info {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home_info p {
  font-size: 40px;
  background-image: none;
  color: #ffffff;
}

.home_info a {
  text-align: center;
  background-color: #ffffff;
  width: 320px;
  border-radius: 5px;
}

.home_info a:hover {
  background-color: #8d8c8c;
}

.home_info a p {
  color: black;
  padding: 15px;
  font-size: 35px;
}

.gray_background {
  background-color: black;
  margin: 20px 0;
  text-align: center;
  padding: 25px;
}

.gray_background h2 {
  font-size: 35px;
  margin: 15px 0;
}

.inner_home_content {
  margin-top: 10px;
  margin-bottom: 40px;
}

.gray_background ul,
ol {
  list-style-type: none; /* Removes bullets and numbers */
  padding: 0; /* Optional: Removes default padding */
  margin: 0; /* Optional: Removes default margin */
}

/***** ABOUT US *****/
.about_us,
.services,
.approach,
.testimonials,
.clients_love {
  background-color: #ffffff;
  margin: 30px;
  padding: 10px 50px;
}

.clients_love {
  background-color: #ffffff;
}

.approach .inner_home_content li {
  text-align: center;
}

.approach,
.services {
  background-color: black;
  color: #ffffff;
}

.approach img {
  width: 150px;
}

.approach ul {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}

.approach li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 370px;
  gap: 20px;
}

.clients_love,
.about_us,
.testimonials {
  border-radius: 8px;
}

.about_us p,
.approach li,
.clients_love li,
.testimonials .testimonial {
  margin: 15px 0;
  font-size: 20px;
  line-height: 125%;
}

.services .inner_home_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  row-gap: 100px;
  align-items: flex-start;
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  gap: 20px;
}

.service img {
  height: 150px;
  border-radius: 5px;
}

.service p {
  font-size: 20px;
}

.clients_love li {
  font-size: 25px;
}

.testimonial {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 15px 0;
}

.testimonial:last-child {
  border-bottom: none;
}

/************* ANIMATIONS *************/

/* Slide to right */
.slideRight {
  /* Apply initial position */
  transform: translateX(-50%);
  animation: slideInR 2s forwards; /* Animation duration and fill mode */
}

@keyframes slideInR {
  0% {
    transform: translateX(-100%); /* Start position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

/* Slide to left */
.slideLeft {
  /* Apply initial position */
  transform: translateX(-50%);
  animation: slideInL 2s forwards; /* Animation duration and fill mode */
}

@keyframes slideInL {
  0% {
    transform: translateX(100%); /* Start position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

/* Fade */
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* Fade Section */
.fade-section {
  opacity: 0;
  transform: translateY(20px); /* Optional for a slight upward movement */
  transition: opacity 1s ease-out, transform 2s ease-out;
}

.fade-section.is-visible {
  opacity: 1;
  transform: translateY(0); /* Reset the transform if you used it */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Responsive styles */
@media screen and (max-width: 1100px) {
  .sss img,
  .sss div {
    width: 400px;
    height: 400px;
  }

  .home_info p,
  .home_info a p {
    font-size: 25px;
  }
}

@media screen and (max-width: 900px) {
  .sss {
    flex-direction: column;
    height: auto;
  }

  .sss img {
    border-radius: 30px 30px 0 0;
  }

  .sss div {
    border-radius: 0 0 30px 30px;
  }
}

@media screen and (max-width: 480px) {
  .sss img,
  .sss div {
    width: 80%;
    height: auto;
  }

  .sss a {
    width: 80%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 700px) {
  .gray_background {
    padding: 10px 0px;
  }

  .about_us {
    padding: 25px 15px;
  }

  .clients_love li {
    font-size: 19px;
  }
}
