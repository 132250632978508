.faqs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
}

.faqs-container h1 {
  font-size: 40px;
  padding: 12px;
}

.faq-item {
  width: 70%;
  border-bottom: 1px solid #c1c1c1;
  padding: 15px 0;
  font-size: 17px;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  font-weight: bold;
  font-size: 19px;
}

.faq-answer {
  padding-top: 5px;
}
