.navbar {
  height: 13vh;
}

.navbar,
.navbar ul {
  display: flex;
}

.navbar {
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
  /*background: linear-gradient(to right, #ffffff, #ffffff, #aaaaaa);*/
  background-color: #fffffc;
}

.navbar li {
  list-style: none;
  margin-left: 20px;
}

.navbar_logo img {
  height: 85px;
}

.nav-item p {
  color: #4d4d4d;
  font-weight: 540;
  font-size: 20px;
  font-family: "Courier New", monospace;
  word-spacing: -6px;
}

.nav-item p:hover {
  font-weight: 550;
  color: black;
}

/* background-color: #333; for .navbar */
/** RESPONSIVE navbar **/
.dropdown-toggle {
  display: none;
}

@media screen and (max-width: 1100px) {
  .navbar ul {
    display: none;
  }

  /* Hide the dropdown content by default */
  .navbar-nav {
    display: none;
    position: absolute; /* Positioning outside of normal document flow */
    background-color: black;
    /*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);*/
    z-index: 1; /* Ensure dropdown is on top of other content */
    right: 0;
    margin-right: 10px;
    margin-top: 5px;
    border: 1px solid #ffffff;
  }

  /* Styling for the dropdown button */
  .dropdown-toggle {
    cursor: pointer;
    display: block; /* Show as a block to take up full width */
  }

  /* Display the dropdown menu on click */
  nav .active {
    display: block;
  }

  /* Adjustments for mobile screens */
  nav {
    display: block;
  }

  .navbar-nav.active {
    display: block; /* Show the navbar when active */
    flex-direction: column;
  }

  .navbar-nav li {
    margin: 10px 0; /* Space out the nav items */
    width: 200px;
    border-bottom: 1px solid #c1c1c1;
    padding: 10px;
    padding-bottom: 15px;
  }

  .navbar-nav li:last-child {
    border-bottom: 0;
    margin-bottom: 5px;
  }

  .navbar-nav {
    text-align: center;
  }

  .navbar-nav p {
    color: #ffffff;
  }

  .nav-item p:hover {
    font-weight: 550;
    color: #ffffff;
  }
}
