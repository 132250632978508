/**************************************************/
/**************** SCROLL TO TOP *******************/

#scrollToTopBtn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99; /* Ensure it's above other elements */
  font-size: 18px;
  border: none;
  outline: none;

  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  margin: 0;
  padding: 0;
}

#scrollToTopBtn img {
  background-color: gray;
  opacity: 100%;
  width: 50px;
  padding: 10px;
  border-radius: 50px;
}
